<template>
  <div class="py-5">
    <b-container class="registration">
      <h2
        data-cy="header"
        class="registration__header"
      >
        Seller Application
      </h2>

      <div>
        <transition
          name="component-fade"
          mode="out-in"
        >
          <keep-alive>
            <component
              :is="stepComponent"
              :is-loading="isLoading"
              :loading-categories="loadingCategories"
              :categories="categories"
              :loading-traffic-sources="loadingTrafficSources"
              :inbound-traffic-sources="inboundTrafficSources"
              :outbound-traffic-sources="outboundTrafficSources"
              :transfer-traffic-sources="transferTrafficSources"
              @submit="onSubmit"
              @back="onBack"
            />
          </keep-alive>
        </transition>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Vue from 'vue'

import CompanyDetailsForm from '@/components/registration/CompanyDetailsForm.vue'
import RepresentativeDetailsForm from '@/components/registration/RepresentativeDetailsForm.vue'
import CallTechnologyDetails from '@/components/registration/CallTechnologyDetails.vue'
import SellerDetailsStep4 from '@/components/registration/SellerDetailsStep4.vue'
import SellerDetailsStep5 from '@/components/registration/SellerDetailsStep5.vue'

export default {
  name: 'SellerRegistrationApply',

  title: 'Seller Application',

  data() {
    return {
      isLoading: false,
      step: 1,
      finalStep: 5,
      loadingCategories: false,
      loadingTrafficSources: false
    }
  },

  computed: {
    ...mapState('registration', [
      'error'
    ]),

    ...mapState('common-entities', ['categories']),

    ...mapGetters('common-entities', [
      'inboundTrafficSources',
      'outboundTrafficSources',
      'transferTrafficSources'
    ]),

    stepComponent() {
      const stepToComponentMap = {
        1: CompanyDetailsForm,
        2: RepresentativeDetailsForm,
        3: CallTechnologyDetails,
        4: SellerDetailsStep4,
        5: SellerDetailsStep5
      }
      return stepToComponentMap[this.step]
    }
  },

  async created () {
    await this.fetchCategories()
    await this.fetchTrafficSources()
  },


  methods: {
    ...mapActions('registration', ['submitSellerRegistrationRequest']),

    ...mapActions('common-entities', ['getCategories', 'getTrafficSources']),

    async onSubmit() {
      if (this.step < this.finalStep) {
        this.step += 1
      } else {
        await this.submitRequest()
      }
    },

    async submitRequest() {
      this.isLoading = true

      try {
        await this.submitSellerRegistrationRequest()
      } finally {
        this.isLoading = false
      }

      if (this.error) {
        Vue.notify({
          group: 'default',
          title: this.error,
          type: 'error',
          duration: 3000
        })
      } else {
        this.$router.push({
          name: 'RegistrationSuccess'
        })
      }
    },

    async fetchCategories() {
      try {
        this.loadingCategories = true
        await this.getCategories()
      } catch (e) {
        console.error(e)
      } finally {
        this.loadingCategories = false
      }
    },

    async fetchTrafficSources() {
      try {
        this.loadingTrafficSources = true
        await this.getTrafficSources()
      } catch (e) {
        console.error(e)
      } finally {
        this.loadingTrafficSources = false
      }
    },

    onBack() {
      if (this.step > 1){
        this.step -= 1
      }
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
<template>
  <div class="py-5">
    <b-container class="registration">
      <h5
        data-cy="header"
        class="registration__header mb-4"
      >
        Are you applying as a buyer or a seller?
      </h5>

      <div>
        <validation-observer
          ref="formValidation"
          v-slot="{ handleSubmit }"
        >
          <b-form
            novalidate
            @submit.stop.prevent="handleSubmit(onSubmit)"
          >
            <BaseFormRadio
              v-model="registrationType"
              :options="registrationTypes"
              data-cy="registrationType"
              stacked
              required
            />

            <div class="text-center">
              <BaseButtonLoader
                button-class="custom-button--white-hover-grad pulse custom-button--wide"
                type="submit"
                data-cy="button-submit"
              >
                Proceed
              </BaseButtonLoader>
            </div>
          </b-form>
        </validation-observer>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Register',

  title: 'Ringba X',

  data() {
    return {
      registrationTypes: ['Buyer', 'Seller'],
      registrationType: null
    }
  },
  methods: {
    onSubmit() {
      if (this.registrationType == 'Buyer') {
        this.$router.push('/buyer-registration-apply')
      } else if (this.registrationType == 'Seller'){
        this.$router.push('/seller-registration-apply')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@include media-breakpoint-up(sm) {
  .registration {
    width: 600px;
  }
}
</style>

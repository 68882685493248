<template>
  <div>
    <h5 class="mb-5">
      Company Details
    </h5>

    <validation-observer
      ref="formValidation"
      v-slot="{ handleSubmit }"
    >
      <b-form
        novalidate
        @submit.stop.prevent="handleSubmit(onSubmit)"
      >
        <BaseFormInput
          id="companyName"
          v-model="details.companyName"
          name="Company Name"
          label="Company Name"
          placeholder="Enter company name"
          type="text"
          required
        />

        <b-form-row>
          <b-col
            sm
          >
            <BaseFormInput
              id="companyAddressLine1"
              v-model="details.companyAddress.addressLine1"
              name="Address 1"
              placeholder="Enter street address"
              label="Address 1"
              type="text"
              required
            />
          </b-col>
          <b-col
            sm
          >
            <BaseFormInput
              id="companyAddressLine2"
              v-model="details.companyAddress.addressLine2"
              name="Address 2"
              placeholder="Optional"
              label="Address 2"
              type="text"
            />
          </b-col>
        </b-form-row>

        <BaseFormSelect
          id="companyCountry"
          v-model="details.companyAddress.countryCode"
          :options="countryOptions"
          label="Country"
          required
        />

        <b-form-row>
          <b-col
            v-if="details.companyAddress.countryCode === 'US'"
            sm
          >
            <BaseFormSelect
              id="companyState"
              v-model="details.companyAddress.state"
              :options="stateOptions"
              label="State"
              required
            />
          </b-col>

          <b-col
            v-else-if="details.companyAddress.countryCode === 'CA'"
            sm
          >
            <BaseFormSelect
              id="companyState"
              v-model="details.companyAddress.state"
              :options="stateOptions"
              label="Province"
              required
            />
          </b-col>

          <b-col
            v-else
            sm
          >
            <BaseFormInput
              id="companyState"
              v-model="details.companyAddress.state"
              name="Company State"
              placeholder="Enter company state"
              label="State"
              type="text"
              required
            />
          </b-col>

          <b-col
            sm
          >
            <BaseFormInput
              id="companyCity"
              v-model="details.companyAddress.city"
              name="Company City"
              placeholder="Enter company city"
              label="City"
              type="text"
              required
            />
          </b-col>

          <b-col
            sm
          >
            <BaseFormInput
              id="companyZip"
              v-model="details.companyAddress.zip"
              name="Company Zip/Postal Code"
              placeholder="Enter Zip/Postal Code"
              label="Zip / Postal Code"
              type="text"
              required
            />
          </b-col>
        </b-form-row>

        <BaseFormInput
          id="companyPhone"
          v-model="details.companyPhone"
          :formatter="phoneNumberFormatter"
          :debounce="500"
          name="Company Phone"
          placeholder="Enter company phone"
          label="Company Phone"
          type="tel"
          rules="phone"
          required
        />

        <BaseFormInput
          id="companyWebsite"
          v-model="details.companyWebsite"
          name="Company Website"
          placeholder="Enter company website"
          label="Company Website"
          rules="url"
          required
        />

        <div class="text-center">
          <BaseButtonLoader
            button-class="custom-button--white-hover-grad pulse"
            type="submit"
          >
            Next
          </BaseButtonLoader>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import formInputMixin from '@/mixins/formInputMixin'
export default {
  name: 'CompanyDetailsForm',

  mixins: [formInputMixin],

  data() {
    return {
      details: {
        companyName: '',
        companyPhone: '',
        companyWebsite: '',
        companyAddress: {
          state: null,
          city: '',
          zip: '',
          countryCode: null,
        },
      }
    }
  },

  computed: {
    ...mapState('country', ['countries', 'usStates', 'canadaProvinces']),
    countryOptions() {
      let countriesOptions = this.countries.map(country => {
        return {
          value: country.code,
          text: country.name
        }
      })

      countriesOptions.unshift({
        value: null,
        text: 'Please select a country'
      })

      countriesOptions.splice(3, 0, {
        value: '',
        text: '---------------------------------------',
        disabled: true
      })

      return countriesOptions
    },
    stateOptions() {
      let stateOptions = []
      if (this.details.companyAddress.countryCode === 'US') {
        stateOptions = this.usStates.map(state => {
          return {
            value: state.abbreviation,
            text: state.name
          }
        })
        stateOptions.unshift({
          value: null,
          text: 'Select State'
        })

      } else if (this.details.companyAddress.countryCode === 'CA') {
        stateOptions = this.canadaProvinces.map(state => {
          return {
            value: state.short,
            text: state.name
          }
        })
        stateOptions.unshift({
          value: null,
          text: 'Select Province'
        })

      }

      return stateOptions
    }
  },

  methods: {
    ...mapActions('registration', ['setCompanyDetails']),
    onSubmit() {
      this.setCompanyDetails({ ...this.details })
      this.$emit('submit')
    }
  }
}
</script>

<style></style>

<template>
  <div>
    <h3 class="mb-5">
      Representative Details
    </h3>

    <validation-observer
      ref="formValidation"
      v-slot="{ handleSubmit }"
    >
      <b-form
        novalidate
        @submit.stop.prevent="handleSubmit(onSubmit)"
      >
        <b-form-row>
          <b-col>
            <BaseFormInput
              id="firstName"
              v-model="details.firstName"
              name="First Name"
              placeholder="Enter First Name"
              label="First Name"
              type="text"
              required
            />
          </b-col>

          <b-col>
            <BaseFormInput
              id="lastName"
              v-model="details.lastName"
              name="Last Name"
              label="Last Name"
              placeholder="Enter Last Name"
              type="text"
              required
            />
          </b-col>
        </b-form-row>

        <BaseFormInput
          id="email"
          v-model.trim="details.email"
          name="Email"
          label="Email"
          placeholder="Enter Email"
          type="email"
          rules="email"
          required
          @blur="handleEmailInput"
        />

        <BaseFormInput
          id="skype"
          v-model="details.skype"
          name="Skype"
          label="Skype"
          placeholder="Enter Skype (Optional)"
          type="text"          
        />

        <BaseFormInput
          id="cellPhone"
          v-model="details.cellPhone"
          name="Cell Phone"
          label="Phone Number"
          description="This should be a mobile number that can receive SMS for completing authentication"
          placeholder="Enter mobile phone number"
          type="tel"
          :formatter="phoneNumberFormatter"
          :debounce="500"
          rules="mobile"
          required
        />

        <div class="justify-content-center d-flex">
          <BaseButtonLoader
            button-class="custom-button--white-hover-grad pulse mr-3"
            @click="onBack"
          >
            Back
          </BaseButtonLoader>

          <BaseButtonLoader
            button-class="custom-button--white-hover-grad pulse"
            type="submit"
          >
            Next
          </BaseButtonLoader>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import formInputMixin from '@/mixins/formInputMixin'
import { mapActions } from 'vuex'

export default {
  name: 'RepresentativeDetailsForm',

  mixins: [formInputMixin],

  data() {
    return {
      details: {
        firstName: '',
        lastName: '',
        email: '',
        skype: '',
        cellPhone: ''
      }
    }
  },

  methods: {
    ...mapActions('registration', ['setRepresentativeDetails']),

    onSubmit() {
      this.handleEmailInput()
      this.setRepresentativeDetails({...this.details})
      this.$emit('submit')
    },

    onBack() {
      this.$emit('back')
    },

    handleEmailInput() {
      this.details = {
        ...this.details,
        email: this.details.email.trim().toLowerCase()
      }
    }
  },
}
</script>

<style>

</style>
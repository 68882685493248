<template>
  <div>
    <h5 class="mb-5">
      Please select types of calls you generate
    </h5>

    <validation-observer
      ref="formValidation"
      v-slot="{ handleSubmit }"
    >
      <b-form
        novalidate
        @submit.stop.prevent="handleSubmit(onSubmit)"
      >
        <validation-provider
          v-if="loadingTrafficSources || inboundTrafficSources.length"
          name="input-inbound-traffic-sources"
        >
          <b-form-group
            label="Inbound"
            label-for="input-inbound-traffic-sources"
            label-class="custom-form-input-label"
          >
            <div v-if="loadingTrafficSources">
              <b-spinner
                class="mr-2 text-muted"
                style="margin-bottom: 2px;"
              />
              <span class="text-muted">Loading Inbound Traffic Sources</span>
            </div>
            
            <v-multiselect-listbox
              v-if="!loadingTrafficSources && inboundTrafficSources.length"
              id="input-inbound-traffic-sources"
              v-model="details.inboundTrafficSourcesIds"
              data-cy="input-inbound-traffic-sources"
              class="msl-multi-select--full-width"
              :options="inboundTrafficSources"
              :reduce-display-property="option => option.name"
              :reduce-value-property="option => option.id"
              search-input-class="custom-form-input custom-form-input--sm"
              search-options-placeholder="Search call type"
              selected-options-placeholder="Search selected"
              selected-no-options-text="No traffic source selected"
              :show-select-all-buttons="false"
            />
          </b-form-group>
        </validation-provider>

        <validation-provider
          v-if="loadingTrafficSources || outboundTrafficSources.length"
          name="input-outbound-traffic-sources"
        >
          <b-form-group
            label="Outbound"
            label-for="input-outbound-traffic-sources"
            label-class="custom-form-input-label"
          >
            <div v-if="loadingTrafficSources">
              <b-spinner
                class="mr-2 text-muted"
                style="margin-bottom: 2px;"
              />
              <span class="text-muted">Loading Outbound Traffic Sources</span>
            </div>

            <v-multiselect-listbox
              v-if="!loadingTrafficSources"
              id="input-outbound-traffic-sources"
              v-model="details.outboundTrafficSourcesIds"
              data-cy="input-outbound-traffic-sources"
              class="msl-multi-select--full-width"
              :options="outboundTrafficSources"
              :reduce-display-property="option => option.name"
              :reduce-value-property="option => option.id"
              search-input-class="custom-form-input custom-form-input--sm"
              search-options-placeholder="Search call type"
              selected-options-placeholder="Search selected"
              selected-no-options-text="No traffic source selected"
              :show-select-all-buttons="false"
            />
          </b-form-group>
        </validation-provider>

        <validation-provider
          v-if="loadingTrafficSources || transferTrafficSources.length"
          name="input-transfer-traffic-sources"
        >
          <b-form-group
            label="Transfers"
            label-for="input-transfer-traffic-sources"
            label-class="custom-form-input-label"
          >
            <div v-if="loadingTrafficSources">
              <b-spinner
                class="mr-2 text-muted"
                style="margin-bottom: 2px;"
              />
              <span class="text-muted">Loading Transfer Traffic Sources</span>
            </div>

            <v-multiselect-listbox
              v-if="!loadingTrafficSources"
              id="input-transfer-traffic-sources"
              v-model="details.transferTrafficSourcesIds"
              data-cy="input-transfer-traffic-sources"
              class="msl-multi-select--full-width"
              :options="transferTrafficSources"
              :reduce-display-property="option => option.name"
              :reduce-value-property="option => option.id"
              search-input-class="custom-form-input custom-form-input--sm"
              search-options-placeholder="Search call type"
              selected-options-placeholder="Search selected"
              selected-no-options-text="No traffic source selected"
              :show-select-all-buttons="false"
            />
          </b-form-group>
        </validation-provider>

        <div
          v-if="!isAnyTrafficSourceSelected"
          class="invalid-feedback d-flex mb-4 mt-2"
        >
          <font-awesome-icon
            :icon="['fas', 'exclamation-triangle']"
            class="mr-1"
            style="margin-top: 2px;"
          />
          Please select at least 1 traffic source
        </div>

        <BaseFormRadio
          v-model="details.worksWithAffiliates"
          description="Note: brokers are allowed as long as transparency is provided on a channel basis. If some of your calls are brokered, please mark yes"
          label="Do you work with affiliates or broker calls in any way?"
          :options="worksWithAffiliatesOptions"
          data-cy="radio-works-with-affiliates"
          stacked
          required
        />

        <div class="justify-content-center d-flex">
          <BaseButtonLoader
            button-class="custom-button--white-hover-grad pulse mr-3"
            @click="onBack"
          >
            Back
          </BaseButtonLoader>

          <BaseButtonLoader
            button-class="custom-button--white-hover-grad pulse"
            type="submit"
          >
            Next
          </BaseButtonLoader>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'SellerDetailsStep4',
  
  props: {
    loadingTrafficSources: {
      type: Boolean,
      default: false
    },

    inboundTrafficSources: {
      type: Array,
      default() {
        return []
      }
    },
        
    outboundTrafficSources: {
      type: Array,
      default() {
        return []
      }
    },
        
    transferTrafficSources: {
      type: Array,
      default() {
        return []
      }
    },
        
  },

  data() {
    return {
      details: {
        inboundTrafficSourcesIds: [],
        outboundTrafficSourcesIds: [],
        transferTrafficSourcesIds: [],
        worksWithAffiliates: false
      },
      worksWithAffiliatesOptions: [
        { text: 'Yes', value: true },
        { text: 'No', value: false }
      ]
    }
  },

  computed: {
    isAnyTrafficSourceSelected() {
      return this.details.inboundTrafficSourcesIds.length || this.details.outboundTrafficSourcesIds.length || this.details.transferTrafficSourcesIds.length
    }
  },

  methods: {
    ...mapActions('registration', ['setSellerTrafficSourcesDetails']),

    onSubmit() {
      if (!this.isAnyTrafficSourceSelected) {
        return
      }
      
      this.setSellerTrafficSourcesDetails({ ...this.details })
      this.$emit('submit')
    },
    onBack() {
      this.$emit('back')
    }
  }
}
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <h5 class="mb-5">
      Call Technology
    </h5>

    <validation-observer
      ref="formValidation"
      v-slot="{ handleSubmit }"
    >
      <b-form
        novalidate
        @submit.stop.prevent="handleSubmit(onSubmit)"
      >
        <BaseFormRadio
          v-model="details.callTechnology"
          label="Call Technology"
          :options="callTechnologyOptions"
          data-cy="callTechnology"
          stacked
          required
        />

        <BaseFormInput
          v-if="details.callTechnology === 'other'"
          id="otherCallTechnology"
          v-model="otherCallTechnology"
          name="Call Technology Name"
          label="Please specify"
          type="text"
          required
        />

        <div class="justify-content-center d-flex">
          <BaseButtonLoader
            button-class="custom-button--white-hover-grad pulse mr-3"
            @click="onBack"
          >
            Back
          </BaseButtonLoader>

          <BaseButtonLoader
            button-class="custom-button--white-hover-grad pulse"
            type="submit"
          >
            Next
          </BaseButtonLoader>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'CompanyDetailsForm',

  data() {
    return {
      details: {
        callTechnology: null,
      },
      otherCallTechnology: null,
      callTechnologyOptions: [
        { text: 'Ringba', value: 'ringba' },
        { text: 'Proprietary', value: 'proprietary' },
        { text: 'Other', value: 'other' }
      ]
    }
  },

  methods: {
    ...mapActions('registration', ['setCallTechnologyDetails']),

    onSubmit() {
      this.setCallTechnologyDetails({ callTechnology: this.details.callTechnology === 'other' ? this.otherCallTechnology : this.details.callTechnology })
      this.$emit('submit')
    },

    onBack() {
      this.$emit('back')
    },
  }
}
</script>

<style></style>

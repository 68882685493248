<template>
  <div>
    <h5 class="mb-5">
      Tell us more about your business
    </h5>

    <validation-observer
      ref="formValidation"
      v-slot="{ handleSubmit, invalid, validated }"
    >
      <b-form
        novalidate
        @submit.stop.prevent="handleSubmit(onSubmit)"
      >
        <validation-provider
          name="input-categories"
          rules="required"
        >
          <b-form-group
            label="What categories are you currently working in?"
            label-for="input-categories"
            label-class="custom-form-input-label"
          >
            <div v-if="loadingCategories">
              <b-spinner
                class="mr-2 text-muted"
                style="margin-bottom: 2px;"
              />
              <span class="text-muted">Loading Categories</span>
            </div>

            <v-multiselect-listbox
              v-if="!loadingCategories"
              id="input-categories"
              v-model="details.currentCategoriesIds"
              data-cy="input-categories"
              class="msl-multi-select--full-width"
              :options="categories"
              :reduce-display-property="option => option.name"
              :reduce-value-property="option => option.id"
              search-input-class="custom-form-input custom-form-input--sm"
              search-options-placeholder="Search category"
              selected-options-placeholder="Search selected"
              selected-no-options-text="No category selected"
              :show-select-all-buttons="false"
            />

            <div
              v-if="!details.currentCategoriesIds.length && invalid && validated"
              class="invalid-feedback d-flex"
            >
              Please select at least 1 category
            </div>
          </b-form-group>
        </validation-provider>

        <BaseFormSelect
          id="monthlyCallsGeneratedRange"
          v-model="details.monthlyCallsGeneratedRange"
          data-cy="input-monthlyCallsGeneratedRange"
          name="input-monthlyCallsGeneratedRange"
          label="How many calls does your company generate on a monthly basis?"
          :options="monthlyCallsGeneratedRangeOptions"
          required
        />

        <BaseFormSelect
          id="annualPPCRevenueRange"
          v-model="details.annualPPCRevenueRange"
          data-cy="input-annualPPCRevenueRange"
          name="input-annualPPCRevenueRange"
          label="What is your pay per call call business’ annual revenue?"
          :options="annualPPCRevenueRangeOptions"
          required
        />

        <BaseFormSelect
          id="timeZonesList"
          v-model="details.timeZoneId"
          data-cy="input-timeZonesList"
          name="input-timeZonesList"
          label="What is your preferred time zone?"
          placeholder="What is your preferred time zone?"
          :options="timeZoneOptions"
          required
        />

        <b-form-group class="mt-5">
          <BaseFormCheckbox
            id="authorizeRingbaToContact"
            v-model="authorizeRingbaToContact"
            data-cy="checkbox-authorizeRingbaToContact"
            name="authorizeRingbaToContact"
            require-checked
            :checked-value="true"
            :unchecked-value="false"
          >
            I authorize Ringba to contact me by email, phone, and SMS
            using automated technology.
          </BaseFormCheckbox>
        </b-form-group>

        <div
          v-if="!authorizeRingbaToContact"
          class="invalid-feedback d-flex mb-2 mt-2 justify-content-center"
        >
          <font-awesome-icon
            :icon="['fas', 'exclamation-triangle']"
            class="mr-1"
            style="margin-top: 2px;"
          />
          Please authorize us to contact you
        </div>


        <div class="justify-content-center d-flex">
          <BaseButtonLoader
            button-class="custom-button--white-hover-grad pulse mr-3"
            @click="onBack"
          >
            Back
          </BaseButtonLoader>

          <BaseButtonLoader
            button-class="custom-button--white-hover-grad pulse"
            type="submit"
            :loading="isLoading"
          >
            Submit
          </BaseButtonLoader>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'SellerDetailsStep5',

  props: {
    isLoading: {
      type: Boolean,
      default: false
    },

    categories: {
      type: Array,
      default() {
        return []
      }
    },

    loadingCategories: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      details: {
        currentCategoriesIds: [],
        monthlyCallsGeneratedRange: null,
        annualPPCRevenueRange: null,
        timeZoneId: 'Eastern Standard Time'
      },
      authorizeRingbaToContact: true,
      monthlyCallsGeneratedRangeOptions: [
        { value: null, text: 'Please select' },
        { value: 'Less than 1,000', text: 'Less than 1,000' },
        { value: '1,000 - 10,000', text: '1,000 - 10,000' },
        { value: '10,000 - 25,000', text: '10,000 - 25,000' },
        { value: '25,000 +', text: '25,000 +' }
      ],
      annualPPCRevenueRangeOptions: [
        { value: null, text: 'Please select' },
        { value: 'Less than $100,000', text: 'Less than $100,000' },
        { value: '$100,000 - 500,000', text: '$100,000 - 500,000' },
        { value: '$500,000 - 1,000,000', text: '$500,000 - 1,000,000' },
        { value: '$1,000,000 - 2,500,000', text: '$1,000,000 - 2,500,000' },
        { value: '$2,500,000 - 10,000,000', text: '$2,500,000 - 10,000,000' },
        { value: '$10,000,000 +', text: '$10,000,000 +' },
        { value: 'Prefer not to disclose', text: 'Prefer not to disclose' }
      ],
    }
  },

  computed: {
    ...mapState('common-entities', ['timeZones']), 
    
    timeZoneOptions() {
      return this.timeZones.map(tz => {
        return {
          value: tz.id,
          text: tz.display
        }
      })
    }
  },

  methods: {
    ...mapActions('registration', ['setSellerBusinessDetails', 'setAuthorizeRingbaToContact']),

    onSubmit() {
      if (this.authorizeRingbaToContact) {
        this.setAuthorizeRingbaToContact(this.authorizeRingbaToContact)
        this.setSellerBusinessDetails({ ...this.details })
        this.$emit('submit')
      }
    },
    onBack() {
      this.$emit('back')
    }
  }
}
</script>

<style lang="scss" scoped></style>
